<template>
   <div class="aiSetup">
        <div class="scroll-box">
            <div class="tr-li">
                <!-- <div class="lable-txt"></div> -->
                <div class="from-input">
                    <el-checkbox v-model="isEnable" label="启用AI识别功能"></el-checkbox>
                </div>
            </div>
            <div>
                <button class="bnt-open" @click="setAiItemInfo()">同步菜品档案</button>
                <!-- <button class="bnt-open" @click="getAiStudyData()">上传本地数据</button>
                <button class="bnt-open" @click="setAiStudyData()">拉取线上数据</button> -->
            </div>
            <p class="explain">注：注册文件开通【AI识别】功能后才可生效使用</p>
        </div>
        <div class="footer-box">
            <button class="btn" @click="back();">返回</button>
            <button class="btn selected" @click="saveConfig()">保存配置</button>
        </div>
   </div>
</template>

<script>
/**AI识别功能 */
export default {
    name:'aiSetup',
    data(){
        return {
            //是否启用 AI识别功能
            isEnable:false,

        }
    },
    mounted(){
        //开启识别
        this.$webBrowser.openYoyoAi().then(d=>{
            if(!(d.status==0 || d.state==0)){
                this.$message.error("AI识别开启失败:"+d.message);
            }
        })
        this.$webBrowser.getAiConfig().then(d=>{//AI识别功能
            console.log("getAiConfig",d)
            if(d){
                this.isEnable=d.isEnable==true;
            }
        });
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**保存配置 */
        saveConfig(){
            let data={
                isEnable:this.isEnable,
            }
            this.$webBrowser.saveAiConfig(data).then(d=>{
                if(d && d.state==0){
                    this.$message.success("保存成功");
                }
            });
        },
        //上传本地数据
        getAiStudyData(){
            const loading = this.$loading({
                text: "上传本地数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$webBrowser.getAiStudyData().then(d=>{
                loading.close();
                console.log("getAiStudyData:",d);
                this.$message.success("上传成功");
            })
        },
        //拉取线上数据
        setAiStudyData(){
            const loading = this.$loading({
                text: "拉取线上数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$webBrowser.setAiStudyData().then(d=>{
                loading.close();
                console.log("setAiStudyData:",d);
                this.$message.success("拉取成功");
            })
        },
        //同步菜品档案
        setAiItemInfo(){
            const loading = this.$loading({
                text: "获取菜品数据同步中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.AllPosFoods().then((d)=>{
                loading.close();
                let data=[];
                d?.forEach(main => {
                    main?.FoodSubCategorys?.forEach(sub=>{
                        sub?.PosFoods?.forEach(it=>{
                            data.push({itemCode:it.Food_Code,itemName:it.Food_Name,pinyin:it.Food_PinYin,markType:0});
                        })
                    })
                });
                this.$webBrowser.setAiItemInfo(data).then(d=>{
                    console.log("setAiItemInfo:",d);
                    if(d && (d.status==0 || d.state==0)){
                        this.$message.success("同步菜品档案成功,请重启app后生效！");
                    }else{
                        this.$message.error("同步菜品档案失败:"+d?.message);
                    }
                })
                
            }).catch(e=>{
                loading.close();
                console.log('未找到菜品数据:',e)
            });
        }
    }
}
</script>

<style lang="scss">
@import './aiSetup.scss';
</style>